import { Content } from "@prismicio/client";
import cn from "clsx";
import {
  PrismicImage,
  PrismicRichText,
  SliceComponentProps,
} from "@prismicio/react";
import { TitlePage } from "@components/commun";
import { Text } from "@components/ui";
import { useWindow } from "@middleware/hooks";
import style from "./MyDietAdvice.module.scss";

/**
 * Props for `MyDietAdvice`.
 */
export type MyDietAdviceProps = SliceComponentProps<Content.MyDietAdviceSlice>;

/**
 * Component for "MyDietAdvice" Slices.
 */
const MyDietAdvice = ({ slice }: MyDietAdviceProps): JSX.Element => {
  const { isMobile } = useWindow();
  const hideInMobile = isMobile && slice.primary.hideImgInMobile;
  const hideInDesktop = !isMobile && slice.primary.hideImgInDesktop;
  const shouldShowImage = !(hideInMobile || hideInDesktop);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <div className={style.wrapper}>
        <div className={style.row}>
          {shouldShowImage && (
            <div className={cn(style.col, style.image)}>
              <PrismicImage field={slice.primary.image} />
            </div>
          )}
          <div className={cn(style.col, style.content)}>
            <div className={cn(style.col, style.titles)}>
              <TitlePage styleClass="caveat" className={style.title}>
                <PrismicRichText field={slice.primary.title} />
              </TitlePage>
              <Text className={style.subtitle}>
                <PrismicRichText field={slice.primary.subtitle} />
              </Text>
            </div>
            <div className={cn(style.col, style.quote)}>
              <Text variant="body">
                <PrismicRichText field={slice.primary.quote} />
              </Text>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyDietAdvice;
